import httpClient from './httpClient';
export class UserClient {

    static END_POINT = '/users';
    //static addItemToListUrl = this.END_POINT + '/additem';

    constructor() {
    }

    static async Login(identifier, password) {

        var request = {
            "identifier": identifier,
            "password": password,
        };

        try {
            var response = await httpClient.post('/users/login', request);

            localStorage.accessToken = response.data.accessToken;
            localStorage.refreshToken = response.data.refreshToken;

            this.LoadUser()

            return true;
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    }

    static async Register(username, email, password) {

        var request = {
            "name": username,
            "emailaddress": email,
            "password": password,
        };
        try {
            var response = await httpClient.post('/users/register', request);
            localStorage.accessToken = response.data.accessToken;
            localStorage.refreshToken = response.data.refreshToken;

            this.LoadUser()

            return true;
        }
        catch (ex) {
            console.log(ex);
            return false;
        }


    }

    static async LoadUser(force) {

        if (force != true && typeof (localStorage.user) !== "undefined") {
            return JSON.parse(localStorage.user);
        }
        try {
            var userReponse = await httpClient.get('users/me?force=' + force ?? false);
            localStorage.user = JSON.stringify(userReponse.data);
            return userReponse.data;
        }
        catch (ex) {
            return null;
        }

    }

    static async IsLoggedIn() {
        console.log(typeof (localStorage.user) === "undefined")
        if (typeof (localStorage.user) === "undefined") {
            var user = await this.LoadUser(true);
            if (user == null) {
                return false;
            }
        }
        return true;
    }

    static async VerifyEmail(token) {
        var decoded = atob(token);
        var requestdata = JSON.parse(decoded);

        var response = await httpClient.post('/users/verifyEmail', requestdata);
        console.log(response);
    }
}