import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../home.vue'

export let activedRoutes = [];

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/advertenties/:categoryId*',
            name: 'listings',
            component: () => import('../listingOverview/listingOverview.vue')
        },
        {
            path: '/advertenties/item/:itemKey',
            name: 'listingsbyitem',
            component: () => import('../itemListingOverview/itemListingOverview.vue')
        },
        //{
        //    path: '/advertenties/:categoryId*/page/:page*/filter/:filters?',
        //    name: 'pagedlistings',
        //    component: () => import('../listingOverview/listingOverview.vue')
        //},
        {
            path: '/advertenties/nieuw/:itemKey*',
            name: 'createListing',
            component: () => import('../listingManagementDetail/listingManagementDetail.vue')
        },
        {
            path: '/advertenties/edit/:id',
            name: 'editListing',
            component: () => import('../listingManagementDetail/listingManagementDetail.vue')
        },
        {
            path: '/advertentie/:id?',
            name: 'listingDetail',
            component: () => import('../listingDetail/listingDetail.vue')
        },

        {
            path: '/items/:categoryId*/:page*',
            name: 'items',
            component: () => import('../itemOverview/itemOverview.vue')
        },
        {
            path: '/items/:categoryId*/page/:page*',
            name: 'pagedItems',
            component: () => import('../itemOverview/itemOverview.vue')
        },
        {
            path: '/item/:itemKey',
            name: 'item',
            component: () => import('../itemDetail/itemDetail.vue')
        },
        {
            path: '/wensenlijsten',
            name: 'wishlists',
            component: () => import('../wishlistOverview/wishlistOverview.vue'),
        },
        {
            path: '/wensenlijst/:listId',
            name: 'wishlistdetail',
            component: () => import('../wishlistDetail/wishlistDetail.vue')
        },
        {
            path: '/inventaris',
            name: 'inventory',
            component: () => import('../wishlistOverview/wishlistOverview.vue')
        },
        {
            path: '/inventaris/:listId',
            name: 'inventorydetail',
            component: () => import('../wishlistDetail/wishlistDetail.vue')
        },
        {
            path: '/favorieten',
            name: 'favorites',
            component: () => import('../favorites/favorites.vue'),
        },
        {
            path: '/berichten',
            name: 'messages',
            component: () => import('../messages.vue'),
        },
        {
            path: '/berichten/:messageThreadId',
            name: 'messageThread',
            component: () => import('../messages.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../login.vue'),
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import('../login.vue'),
        },
        {
            path: '/registreren',
            name: 'register',
            component: () => import('../login.vue'),
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('../dashboard/dashboard.vue'),
        },
        {
            path: '/listingManagement',
            name: 'listingManagement',
            component: () => import('@/components/listingManagementOverview/listingManagementOverview.vue'),
        },
        {
            path: '/profiel/:userId',
            name: 'userDetail',
            component: () => import('@/components/userReviewOverview/userReviewOverview.vue'),
        },
        {
            path: '/recommendations',
            name: 'recommendations',
            component: () => import('@/components/userRecommendationOverview/userRecommendationOverview.vue'),
        },
        {
            path: '/kaart',
            name: 'maps',
            component: () => import('@/components/maps.vue'),
        },
        {
            path: '/register-complete',
            name: 'emailVerify',
            component: () => import('@/components/emailVerify/emailVerify.vue'),
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'notfound',
            component: () => import('@/components/notFound.vue'),
        },
        {
            path: '/buy',
            name: 'buy',
            component: () => import('@/components/buy/buy.vue'),
        },
        {
            path: '/sitemap',
            name: 'sitemap',
            component: () => import('@/components/sitemap.vue'),
        },
    ]
})
router.beforeEach((to, from, next) => {
    activedRoutes = [];
    to.matched.forEach((record) => { activedRoutes.push(record) })
    next();
});

export default router
