<template>
    <main class="">
        <section class="section-box">
            <div class="banner-hero banner-1 mt-10">

                <div class="row">
                    <div class="col-lg-8">
                        <div class="banner-big  mb-10"
                             style="background-image: url(assets/imgs/train.png); background-color: #90c395;">
                            <div class="row">
                                <div class="col-lg-12 col-md-7 col-sm-12">
                                    <p class="">

                                        <b>{{ $t('home.introHeader') }}</b>
                                        <br /><br />
                                        <span v-html="$t('home.introText',{ itemCount: itemCount.toLocaleString()})"></span>
                                        <br /><br />
                                        <router-link :to="{name: 'register'}">
                                            {{$t('home.introLink')}}
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-none d-lg-block">
                        <div class="row">
                            <div class="col-lg-12 col-md-6 col-sm-12">
                                <div class="mapBanner bg-13" style=" background-image: url(/nederland.svg)">
                                    <h1 class="mb-10">{{ $t('home.mapBanner.nearby') }}</h1>
                                    <p class="color-brand-3 font-desc">{{ $t('home.mapBanner.adsNearby') }}</p>
                                    <div class="mt-20">
                                        <router-link :to="{name: 'maps'}" class="btn btn-buy btn-arrow-right">{{ $t('home.mapBanner.viewMap') }}</router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-box">
            <div class="">
                <div class="row">
                    <div class="col-lg-12 mb-5">
                        <div class="border-bottom">
                            <h1>{{$t('global.items')}}</h1>

                        </div>
                        <p class="homeDescription mr-5"
                           v-html="$t('home.catalogDescription',{ itemCount: itemCount.toLocaleString()})">
                        </p>
                    </div>
                    <div class="col-lg-12">
                        <div class="row test g-0">
                            <div class="col-3" v-for="category in categories" :key="category.name">
                                <router-link :to="{ name: 'items', params: { categoryId: category.name }}"
                                             class="brandImage">
                                    <img :src="category.image" :alt="category.name" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-0 mt-10" >
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12" v-for="category in categoryTree" v-bind:key="category.id">
                        <div class="card-grid-style-2 card-grid-style-2-small hover-up categories">
                            <div class="image-box">
                                <router-link :to="{name: 'items', params: { categoryId: category.path}}">
                                    <img class="categoryImage" :src="'assets/imgs/category/'+category.path+'.jpg'" :alt="category.titles[this.$i18n.locale.toUpperCase()]" />
                                </router-link>
                            </div>
                            <div class="info-right">
                                <router-link :to="{name: 'items', params: { categoryId: category.path}}"
                                             class="color-brand-3 font-sm-bold">
                                    <h6> {{category.titles[this.$i18n.locale.toUpperCase()]}}</h6>
                                </router-link>
                                <ul class="list-links-disc">
                                    <li  v-for="subCategory in category.categories.slice(0,3)" v-bind:key="subCategory.id">
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: [category.path,subCategory.path]}}">
                                            {{subCategory.titles[this.$i18n.locale.toUpperCase()]}}
                                        </router-link>
                                        </li>
                                </ul>
                                <router-link class="btn btn-gray-abs" :to="{name: 'items', params: { categoryId: [category.path]}}">
                                    {{$t('home.showAll')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!--
                    <div class=" col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="card-grid-style-2 card-grid-style-2-small hover-up categories">
                            <div class="image-box">
                                <router-link :to="{name: 'items', params: { categoryId: ['marklin']}}">
                                    <img class="categoryImage" src="assets/imgs/category/marklin.jpg" alt="marklin" />
                                </router-link>
                            </div>
                            <div class="info-right">
                                <router-link :to="{name: 'items', params: { categoryId: ['marklin']}}" class="color-brand-3 font-sm-bold">

                                    <h6>Marklin</h6>
                                </router-link>
                                <ul class="list-links-disc">
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','h0']}}">H0 spoor</router-link>
                                        <ul style="margin-left:20px">
                                            <li>
                                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','h0', 'rails']}}">Rails</router-link>
                                            </li>
                                            <li>
                                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','h0', 'locomotieven']}}">Lokomotieven</router-link>
                                            </li>
                                            <li>
                                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','h0', 'rijtuigen']}}">Rijtuigen</router-link>
                                            </li>
                                        </ul>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','z']}}">Z spoor</router-link>

                                    </li>
                                    <li>
                                    </li>
                                </ul>
                                <router-link class="btn btn-gray-abs" :to="{name: 'items', params: { categoryId: ['marklin']}}">
                                    {{$t('home.showAll')}}
                                </router-link>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="card-grid-style-2 card-grid-style-2-small hover-up categories">
                            <div class="image-box">
                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix']}}">
                                    <img class="categoryImage" src="assets/imgs/category/trix.jpg" alt="trix" />
                                </router-link>
                            </div>
                            <div class="info-right">
                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix']}}">
                                    <h6>Trix</h6>
                                </router-link>
                                <ul class="list-links-disc">
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix','h0', 'rails']}}">H0 spoor</router-link>
                                    </li>
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix','h0', 'lokomotieven']}}">Lokomotieven</router-link>
                                    </li>
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix','h0', 'rijtuigen']}}">Rijtuigen</router-link>
                                    </li>
                                </ul>
                                <router-link class="btn btn-gray-abs" :to="{name: 'items', params: { categoryId: ['trix']}}">
                                    {{$t('home.showAll')}}

                                </router-link>
                            </div>
                        </div>
                    </div>

                        -->
                </div>

                <div class="row g-0">
                    <div class="col-12 col-md-6 mb-5">
                        <div class="mr-10">
                            <div class="border-bottom">
                                <h1>{{$t('global.inventory')}}</h1>

                            </div>
                            <div class="row g-0 homeDescription">
                                <div class="col-6">
                                    <div class="pr-10" v-html="$t('home.inventoryDescription')">
                                    </div>
                                </div>
                                <div class="col-6 text-end">
                                    <img src="/screenshot1.png" height="300" class="screenshot" />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-12 col-md-6 mb-5">
                        <div class="mr-10">
                            <div class="border-bottom">
                                <h1>{{$t('global.wishlists')}}</h1>

                            </div>
                            <div class="row g-0 homeDescription">

                                <div class="col-6 text-end  d-block d-sm-none">
                                    <img src="/screenshot2.png" height="300" class="screenshot" />
                                </div>
                                <div class="col-6">
                                    <div class="pl-10" v-html="$t('home.wishlistDescription')">
                                    </div>
                                </div>

                                <div class="col-6 text-end d-none d-md-block">
                                    <img src="/screenshot2.png" height="300" />
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="border-bottom">
                                    <h1>{{$t('global.listings')}}</h1>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="homeDescription">

                                    <div v-html="$t('home.listingDescription')">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mr-10">
                                    <div class="border-bottom">

                                    </div>
                                    <div class="mt-10">
                                        <ListingsComponent limit="3" width="full" :show-navigation="false"></ListingsComponent>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </main>

</template>

<script>
    import ListingsComponent from "@/components/listingscomponent/listingscomponent.vue";
    import httpClient from '@/httpClients/httpClient';

    export default {
        name: 'items',
        data() {
            return {
                categories: [
                    { name: 'fleischmann', image: 'brands/fleischmann.png' },
                    { name: 'marklin', image: 'brands/marklin.svg' },
                    { name: 'trix', image: 'brands/trix.png' },
                    { name: 'faller', image: 'brands/faller.svg' },
                    { name: 'lgb', image: 'brands/lgb.png' },
                    { name: 'roco', image: 'brands/roco.svg' },
                    { name: 'arnold', image: 'brands/arnold.svg' },
                    { name: 'brawa', image: 'brands/brawa.png' },
                    { name: 'lionel', image: 'brands/lionel.jpg' },
                    { name: 'piko', image: 'brands/piko.png' },
                    { name: 'kato', image: 'brands/kato.png' },
                    { name: 'viessmann', image: 'brands/viessmann.png' },
                    { name: 'hornby', image: 'brands/hornby.png' },
                    { name: 'noch', image: 'brands/noch.png' },
                    { name: 'bachmann', image: 'brands/bachmann.png' },
                    { name: 'busch', image: 'brands/busch.png' },

                ],
                itemCount: 0,
                categoryTree: null
            }
        },
        props: {
            msg: String,
        },
        components: {
            ListingsComponent,
        },
        methods: {

            async loadPage() {
                var result = await httpClient.get("/categories");
                this.itemCount = parseInt(result.data.root.itemCount) - 1;

                let categoryTree = result.data.categories;
                const shuffled = categoryTree.sort(() => 0.5 - Math.random());
                // Get sub-array of first n elements after shuffled
                this.categoryTree = shuffled.slice(0, 3);
                
            }
        },
        mounted() {
            this.loadPage();
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .test > div > a {
        display: block;
        border: 1px solid #b7b7b7;
        border-radius: 4px;
        vertical-align: middle;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 5px 5px 0px 0px;
        text-align: center;
    }

        .test > div > a > img {
            height: 13px;
        }

    img {
        /*border-radius: 5px;*/
    }

    .homeDescription {
        border: 1px solid #b7b7b7;
        margin-top: 10px;
        padding: 10px;
        border-radius: 4px;
    }

    .screenshot {
        border-radius: 5px;
    }

    .categoryImage {
        border-radius: 6px;
        border: 1px solid;
    }
</style>


